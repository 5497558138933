import { AfterViewInit, Component, computed, effect, ElementRef, HostListener, inject, Injector, Input, input, output, signal, viewChild } from '@angular/core'

import { Subscription } from 'rxjs'

import { customClick } from '#core/utils/mouseEvent'
import { ChildElementTreeNode } from '#modules/workspace/models/element-node'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'
import { AtomType, IChildElement, IShadowElement, ISize } from '#modules/workspace/types/element'

import { ElementBaseComponent } from '../element-base.component'

@Component({
  selector: 'ace-element-group',
  standalone: true,
  imports: [ElementBaseComponent],
  templateUrl: './element-group.component.html'
})
export class ElementGroupComponent {
  id = input.required<string>()
  children = input.required<IChildElement<AtomType>[]>()
  size = input.required<ISize>()
  rotation = input<number>(0)
  preview = input<boolean>(false)
  scale = input<number>(1)
  isVirtual = input<boolean>(false)
  locked = input<boolean>(false)
  interaction = input<Record<string, IShadowElement>>({})
  parsedChildren = computed(() => {
    const groupScale = this.scale()
    const children = this.children()
    return children.map(
      child =>
        new ChildElementTreeNode({
          ...child,
          scale: child.scale * groupScale,
          position: {
            x: child.position.x * groupScale,
            y: child.position.y * groupScale
          }
        })
    )
  })
  dragStart = output<{ id: string; $event: { clientX: number; clientY: number }; parent?: string }>()
  edit = output<string | false>()
  uiStore = inject(StageUiStore)
  textStore = inject(TextStore)

  interactDiv = viewChild<ElementRef>('interactDiv')

  selected = computed(() => {
    return this.uiStore.selectedIds().includes(this.id()) && this.uiStore.selectedTarget() === 'element'
  })

  private subscription = new Subscription()

  // private elRef = inject(ElementRef)
  constructor() {
    effect(() => {
      if (this.interactDiv()) {
        const selected = this.selected()
        this.subscription.unsubscribe()
        this.subscription = new Subscription()
        this.subscription.add(
          customClick({
            element: (this.interactDiv() as ElementRef).nativeElement,
            onMousedown: $event => {
              if (this.locked()) return
              if (!selected && !this.isVirtual() && !this.uiStore.selectedIds().find(id => this.children().find(child => child.id === id))) {
                this.selectElement($event)
              }

              // 触发拖拽事件
              if (!this.textStore.editing() && $event.button === 0) {
                this.dragStart.emit({
                  id: this.id(),
                  $event: { clientX: $event.clientX, clientY: $event.clientY }
                })
              }
            }
          }).subscribe($event => {
            if (this.isVirtual()) return
            if ($event.shiftKey) {
              if (selected) {
                this.unselectElement()
              }
            } else {
              if (this.locked() && !selected) {
                this.selectElement($event)
              }
            }
          })
        )
      }
    })
  }

  @HostListener('mousedown', ['$event']) onMouseDown($event: MouseEvent) {
    if (!this.locked()) {
      $event.stopPropagation()
    }
  }

  @HostListener('mouseup', ['$event']) protected onMouseUp($event: MouseEvent) {
    $event.stopPropagation()
  }

  protected childDragStart($event: { id: string; $event: { clientX: number; clientY: number } }) {
    this.dragStart.emit({ ...$event, parent: this.id() })
  }

  protected selectElement($event: MouseEvent) {
    if (this.selected()) return

    if (this.uiStore.selectedTarget() === 'element') {
      if ($event.shiftKey && !this.uiStore.selectedRootElements()[0].locked) {
        this.uiStore.addSelection(this.id())
      } else {
        this.uiStore.resetSelection('element', this.id())
      }
    } else {
      this.uiStore.resetSelection('element', this.id())
    }
  }

  protected unselectElement() {
    this.uiStore.removeSelection(this.id())
  }
}
