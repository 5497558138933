<div #interactDiv class="relative h-full w-full origin-top-left">
  <!--  <div class=" h-full w-full" >-->
  @for (child of parsedChildren(); track child.id) {
    <div class="absolute" [style.transform]="'translate(' + child.position.x + 'px, ' + child.position.y + 'px ) rotate(' + child.rotation + 'deg)'">
      <!--        <div class="w-full h-full">-->
      <ace-element
        [scale]="child.scale"
        [id]="child.id"
        [parent]="id()"
        [setting]="child.setting"
        [size]="child.size"
        [category]="child.category"
        [preview]="preview()"
        [locked]="locked()"
        (dragStart)="childDragStart($event)"
      ></ace-element>
      <!--        </div>-->
    </div>
  }
  <!--  </div>-->
</div>
